<template>
  <div>
    <car-parking-popup-branch
      :temp-search-branch="tempSearchBranch"
      :is-popup-branch-active.sync="isPopupBranchActive"
      @select-item="fncSelectBranch"
    />
    <car-parking-popup-reg-num
      :temp-search-reg-num="tempSearchRegNum"
      :is-popup-reg-num-active.sync="isPopupRegNumActive"
      @select-item="fncSelectRegNum"
    />
    <b-modal
      ref="refModalDatePickerDateStr"
      centered
      title="วันที่ระหว่าง"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateStr"
        class="mt-1"
        locale="th"
        @input="refModalDatePickerDateStr.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerDateEnd"
      centered
      title="ถึง"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateEnd"
        class="mt-1"
        locale="th"
        @input="refModalDatePickerDateEnd.hide()"
      />
    </b-modal>
    <b-card
      title="ที่จอดรถ"
      class="mt-1"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-input-group v-show="!branchCode">
              <b-form-input
                id="branchCode"
                v-model="inputSearchBranch"
                placeholder="ค้นหาพอร์ต"
                trim
                @keydown.enter.native="
                  fncSearchBranch(inputSearchBranch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBranch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="branchCode">
              <b-form-input
                id="branchCode"
                :value="branchName"
                trim
                disabled
              />
              <b-input-group-append v-if="!isDefaultBranchFromLogin">
                <b-button
                  variant="primary"
                  @click="fncCancelBranch()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค้นหาเลขทะเบียน
              </div>
            </template>
            <b-input-group v-show="!regNum1 && !regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                @keydown.enter.native="refInputSearchRegNum2.focus()"
              />
              <b-form-input
                id="inputSearchRegNum2"
                ref="refInputSearchRegNum2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
                @keydown.enter.native="fncSearcRegNum(inputSearchRegNum1, inputSearchRegNum2)"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupRegNum()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="regNum1 || regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="inputSearchRegNu2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%; margin-left: 5px;"
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelRegNum()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่ระหว่าง
              </div>
            </template>
            <div class="form-group">
              <div class="input-group">
                <b-form-input
                  id="inputDateStr"
                  :value="resolveFormatDate(DateStr)"
                  trim
                  readonly
                  placeholder="วันที่เริ่มต้น"
                  @click="fncShowDatepickerDateStr()"
                />
                <div class="input-group-prepend input-group-append">
                  <span class="input-group-text">ถึง</span>
                </div>
                <b-form-input
                  id="inputDateEnd"
                  :value="resolveFormatDate(DateEnd)"
                  trim
                  readonly
                  placeholder="วันที่สิ้นสุด"
                  @click="fncShowDatepickerDateEnd()"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1200 ? 5 : 12">
          <b-form-group
            label-for="events"
            label-cols-md="3"
            label-cols-sm="12"
            :label-align="$store.state.app.windowWidth >= 1200 ? 'right' : 'left'"
          >
            <template v-slot:label>
              <div style="margin-top:15px;">
                ตัวเลือก
              </div>
            </template>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="1"
                style="margin-top:10px;"
              >
                ระบุวันสิ้นสุด
              </b-form-radio>
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="2"
                style="margin-top:10px;"
              >
                ไม่ระบุวันสิ้นสุด
              </b-form-radio>
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="3"
                style="margin-top:10px;"
              >
                ทั้ง 2 เงื่อนไข
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySubmitButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                @click="fncSubmit()"
              >
                ค้นหาข้อมูล
              </b-button>
            </b-overlay>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              @click="fncResetForm()"
            >
              ยกเลิก
            </b-button>
            <b-button
              class="ml-1"
              variant="success"
              :to="{ name: 'car-parking-form-add' }"
            >
              เพิ่มรายการ
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <car-parking-result
      :data-result="dataResult"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay, BFormRadio,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import {
  VDatePicker,
} from 'vuetify/lib'
import CarParkingPopupRegNum from './car-parking-popup/CarParkingPopupRegNum1.vue'
import CarParkingPopupBranch from './car-parking-popup/CarParkingPopupBranch.vue'
import CarParkingResult from './CarParkingResult.vue'

import CarParkingStoreModule from './CarParkingStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    VDatePicker,
    BFormRadio,
    CarParkingPopupBranch,
    CarParkingPopupRegNum,
    CarParkingResult,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appCarParking'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, CarParkingStoreModule)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)

    // ประกาศตัวแปร radio
    const radioChoice = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const tempSearchBranch = ref(null)
    const tempSearchRegNum = ref(null)
    const dataResult = ref([])
    const isDefaultBranchFromLogin = ref(false)
    const DateStr = ref(null)
    const DateEnd = ref(null)
    const KnownEnd = ref(null)
    const UnKnownEnd = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupRegNumActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)

    // ประกาศตัวแปร Ref
    const refModalDatePickerDateStr = ref(null)
    const refModalDatePickerDateEnd = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateStr = () => {
      refModalDatePickerDateStr.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateEnd = () => {
      refModalDatePickerDateEnd.value.show()
    }

    const fncKnown = () => {
      if (radioChoice.value === '1') {
        KnownEnd.value = 'Y'
        UnKnownEnd.value = null
      } else if (radioChoice.value === '2') {
        KnownEnd.value = null
        UnKnownEnd.value = 'Y'
      } else if (radioChoice.value === '3') {
        KnownEnd.value = 'Y'
        UnKnownEnd.value = 'Y'
      }
      console.log(fncKnown)
    }

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupRegNum = search => {
      if (search) tempSearchRegNum.value = search
      else tempSearchRegNum.value = null

      isPopupRegNumActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเลขทะเบียน */
    const fncSearcRegNum = (val1, val2) => {
      const payload = {
        action: 'LIST',
        events: 'CONTRACT',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        TEXT_SEARCH: '',
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_REG`, payload)
        .then(response => {
          const getSearchResult = response.data.responseData.filter(
            item => item.regnum1.toLowerCase().indexOf(val1) > -1 && item.regnum2.toLowerCase().indexOf(val2) > -1,
          )

          if (getSearchResult.length === 1) {
            regNum1.value = getSearchResult[0].regnum1
            regNum2.value = getSearchResult[0].regnum2
          } else if ((getSearchResult.length > 1)) {
            fncOpenPopupRegNum(`${val1}-${val2}`)
          }
        })
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelRegNum = () => {
      regNum1.value = null
      regNum2.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectRegNum = data => {
      inputSearchRegNum1.value = data.regnum1
      inputSearchRegNum2.value = data.regnum2
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    const fncSubmit = () => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)
      if (radioChoice.value === '1') {
        KnownEnd.value = 'Y'
        UnKnownEnd.value = null
      } else if (radioChoice.value === '2') {
        KnownEnd.value = null
        UnKnownEnd.value = 'Y'
      } else if (radioChoice.value === '3') {
        KnownEnd.value = 'Y'
        UnKnownEnd.value = 'Y'
      }
      const payload = {
        action: 'LIST',
        events: 'PARKING',
        function: 'GET',
        USERNAME: username.value ? username.value : JSON.parse(localStorage.getItem('userData')).member_no,
        CPNCOD_IN: null,
        CPNBRNCOD_IN: branchCode.value,
        REGNUM1_IN: inputSearchRegNum1.value,
        REGNUM2_IN: inputSearchRegNum2.value,
        DATE_STR: DateStr.value,
        DATE_END: DateEnd.value,
        KNOWN_ENDDATE: KnownEnd.value, // KnownEnd.value,
        UNKNOWN_ENDDATE: UnKnownEnd.value, //  UnKnownEnd.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_PARKING`, payload)
        .then(response => {
          console.log(payload, 'payload')
          console.log(response.data.responseData, 'res')
          if (response.data.responseData.length) {
            dataResult.value = response.data.responseData
          } else {
            console.log('ไม่พบข้อมูลที่ค้นหา')
          }
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      inputSearchBranch.value = null
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null

      branchCode.value = null
      branchName.value = null
      regNum1.value = null
      regNum2.value = null
      tempSearchBranch.value = null
      tempSearchRegNum.value = null
      dataResult.value = []
      DateStr.value = null
      DateEnd.value = null
      radioChoice.value = null
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      // setMinDatePickerStart,
      // setMaxDatePickerStart,
      // setMinDatePickerEnd,
      // setMaxDatePickerEnd,

      // ประกาศตัวแปร Ref
      refModalDatePickerDateStr,
      fncShowDatepickerDateStr,
      refModalDatePickerDateEnd,
      fncShowDatepickerDateEnd,
      resolveFormatDate,
      paginateArray,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchRegNum1,
      inputSearchRegNum2,

      // ประกาศตัวแปร radio
      radioChoice,
      fncKnown,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      regNum1,
      regNum2,
      tempSearchBranch,
      tempSearchRegNum,
      dataResult,
      isDefaultBranchFromLogin,
      DateStr,
      DateEnd,
      KnownEnd,
      UnKnownEnd,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupRegNumActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBranch,
      fncSearchBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncOpenPopupRegNum,
      fncSearcRegNum,
      fncCancelRegNum,
      fncSelectRegNum,
      fncSubmit,
      fncResetForm,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only
.delete-margin {
  margin-bottom: -60px !important;
} */
</style>
