<template>
  <b-card title="ตารางข้อมูล">
    <div>
      <b-table-simple
        caption-top
        bordered
        class="position-relative mb-2"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              เรียกดู
            </b-th>
            <b-th class="text-center">
              แก้ไข
            </b-th>
            <b-th class="text-center">
              พอร์ต
            </b-th>
            <b-th class="text-center">
              ทะเบียน
            </b-th>
            <b-th class="text-center">
              ชื่อลูกค้า
            </b-th>
            <b-th class="text-center">
              วันที่เริ่มต้น
            </b-th>
            <b-th class="text-center">
              วันที่สิ้นสุด
            </b-th>
            <b-th class="text-center">
              สาเหตุ
            </b-th>
            <b-th class="text-center">
              หมายเหตุ
            </b-th>
            <b-th class="text-center">
              วันที่บันทึก
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="dataResult.length">
            <b-tr
              v-for="(item, index) in paginateArray(
                dataResult,
                perPage,
                currentPage
              )"
              :key="index"
            >
              <b-td class="text-center">
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  @click="fncViewItem(item)"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-td>
              <b-td class="text-center">
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  :disabled=" item.edit_enable === 'N' ? true : false"
                  @click="fncEditItem(item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-td>
              <b-td class="text-center">
                {{ item.cpnbrncod_desc }}
              </b-td>
              <b-td class="text-center">
                {{ `${item.regnum1}-${item.regnum2}` }}
              </b-td>
              <b-td class="text-center">
                {{ item.customer_name }}
              </b-td>
              <b-td class="text-center">
                {{ resolveFormatDate(item.park_date_str) }}
              </b-td>
              <b-td class="text-center">
                {{ resolveFormatDate(item.park_date_end) }}
              </b-td>
              <b-td class="text-center">
                {{ item.parking_reason }}
              </b-td>
              <b-td class="text-center">
                {{ item.remark }}
              </b-td>
              <b-td class="text-center">
                {{ resolveFormatDateTime(item.crtdtetme) }}
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr>
              <b-td
                colspan="11"
                class="text-center"
              >
                <p style="margin-top:5px; margin-bottom:5px;">
                  ไม่พบรายการที่ตรงกัน
                </p>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataResult.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,
  },

  props: {
    dataResult: {
      type: [Object, Array],
      required: true,
    },
  },

  setup() {
    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)

    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const resolveFormatDateTime = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY เวลา HH:MM')
      : null)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* เมื่อกดปุ่มแก้ไขข้อมูลที่รายการ */
    const fncEditItem = data => {
      router.push({ name: 'car-parking-edit', params: { regnum1: data.regnum1, regnum2: data.regnum2 } })
    }

    /* เมื่อกดปุ่มลบรายการ */
    const fncViewItem = data => {
      router.push({ name: 'car-parking-view', params: { regnum1: data.regnum1, regnum2: data.regnum2 } })
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncEditItem,
      fncViewItem,

      // ส่วนการทำงานแสดงผล UI
      paginateArray,
      resolveFormatDate,
      resolveFormatDateTime,
    }
  },
}
</script>

<style>

</style>
